h1,
h2,
h3,
p,
a {
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  font-weight: 600;
  color: #5c993e;
  text-decoration: none;
}

h1 {
  font-size: 60px;
  font-weight: 600;
}

h2 {
  font-size: 36px;
  font-weight: 600;
}

h3 {
  font-size: 30px;
  font-weight: 600;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.input-flex .input-wrapper:first-child {
  margin-right: 20px;
}

.input-label {
  font-size: 16px;
  color: #616368;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.input-icon {
  position: absolute;
  bottom: 12px;
  right: 10px;
  width: 20px;
  cursor: pointer;
  opacity: 0.5;
}

input{
  height: 45px;
  background-color: #efefef;
  border: solid 1px #dedede;
  border-radius: 6px;
  width: 100%;
  padding-left: 20px;
  min-width: 250px;
}
.error-message{
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
  position: absolute;  
   bottom: -15px;   
  left: 0; 
}
textarea{
  height: 60px;
  background-color: #efefef;
  border: solid 1px #dedede;
  border-radius: 6px;
  width: 100%;
  padding-left: 20px;
  min-width: 250px;
  resize: none;
}

.file-wrapper {
  position: relative;
  height: 100px;
  background-color: #efefef;
  border: solid 1px #dedede;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-icon {
  width: 50px;
}
.input-container.error {
    border-color: red;
}
.file-wrapper p {
  font-size: 16px;
  font-weight: 600;
  color: #5c993e;
}

.file-wrapper input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}

@media all and (max-width: 1040px) {
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  p,
  a {
    font-size: 14px;
  }
  .input-flex {
    flex-direction: column;
  }
  .input-flex .input-wrapper:first-child {
    margin-right: 0px;
  }
}
