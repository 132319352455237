.navbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-bottom: solid 1px #dedede;
  padding: 20px 0;
}

.navbar1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.navbar-inner {
  width: 100%;
  max-width: 1200px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
}

.navbar .logo {
  background-image: url("../images/logo.png");
  width: 160px;
  height: 90px;
  background-size: 160px 90px;
}

.navbar1-inner {
  width: 100%;
  max-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar1 .logo1 {
  background-image: url("../images/logo.png");
  width: 160px;
  height: 90px;
  background-size: 160px 90px;
  cursor: pointer;
}
.logo {
    cursor: pointer;
}

@media all and (max-width: 1040px) {
  .navbar1-inner {
    width: 100%;
    max-width: 1200px;
    display: flex;
    padding: 0px 20px;
    align-items: center;
    justify-content: space-between;
  }
}
