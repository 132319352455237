.not-found {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found-title {
    margin-bottom: 20px;
}

.not-found-content {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0 40px 0;
}

.not-found-content img {
    max-width: 100%;
    height: auto;
}

.not-found-content p {
    color: #616368;
}

@media (max-width: 600px) {
    .not-found-content p {
        text-align: center;
    }
}