.search {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
}

.search-title {
  margin-bottom: 20px;
}

.search-form {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

.search-form .input-wrapper {
  width: calc(20% - 15px);
}

.search-form input {
  min-width: inherit;
}

.search-form .input-wrapper:not(:first-child) {
  margin-left: 15px;
}

.search-form .button {
  margin-left: 100px;
}

.input-container.error {
  border-color: red;
}

.search-result {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 20px;
  /* margin: 0px 70px; */
  max-width: 1200px;
}


.search-item {
  width: calc(25% - 15px);
  height: 350px;
  margin-right: 20px;
  border-radius: 10px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.search-item:nth-child(4n) {
  margin-right: 0;
}

.search-item:hover {
  -webkit-box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.1);
}

.item-pic {
  width: 100%;
}

/* img.item-pic {
  width: 100%; 
  height: 200px; 
  object-fit: cover; 
  border-radius: 8px; 
  margin-bottom: 10px;
} */
.search-h1 {
  font-size: 18px;
  color: black;
  padding: 10px 20px 5px 20px;

}

.search-h2 {
  font-size: 16px;
  color: #616368;
  padding: 0px 20px;
}

.search-item-location {
  display: flex;
  align-items: center;
  padding: 0 20px 10px 20px;
}

.search-item-location img {
  width: 15px;
  margin-right: 5px;
}

.search-h3 {
  color: #5c993e;
}

/* Pour tous les navigateurs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.button.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

/* Pour Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media all and (max-width: 1040px) {
  .search-content {
    padding-top: 20px;
  }

  .search-form {
    flex-direction: column;
    align-items: center;
  }

  .search-form>div {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .search-form .input-wrapper {
    width: 100%;
  }

  .search-item {
    width: calc(50% - 15px);
  }

  .search-item:nth-child(2n) {
    margin-right: 0;
  }

  .flex-item {
    flex: 100%;
  }
}

.search-h2 a {
  color: #5c993e;
  text-decoration: none;
  font-size: 14px;

}

.noresult {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.search-gif {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .search-gif {
    display: none;
  }
}


@media all and (max-width: 480px) {
  .search-item {
    width: 100%;
    margin-right: 0;
  }

  .search-item:last-of-type {
    margin-bottom: 30px;
    /* marge inférieure de 30px seulement pour le dernier élément */
  }

}