body {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;

}

.shapes-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 1000px;
}

.hand-vfx {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40%;
  max-width: 550px;
  min-width: 500px;
  z-index: -1;
}

.home {
  position: relative;
  width: 100%;
  margin: auto;
  max-width: 1200px;
  padding: 70px 20px;
}

.home-content {
  width: 100%;
  /* max-width: 600px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 60px 0 40px 0; */
  margin: 80px 0 40px 0;
}

.home-content p {
  color: #616368;
}

.home-cta .button-primary {
  margin-right: 25px;
}

.home-cta {
  display: flex;
  align-items: center;
}

@media all and (max-width: 1040px) {
  body {
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
  }

  .home {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0px;
  }

  .home-content {
    width: 100%;
    max-width: inherit;
    text-align: center;
    align-items: center;
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .home-cta {
    flex-direction: column;
  }

  .home-cta .button-primary {
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .hand-vfx {
    display: none;
  }
}