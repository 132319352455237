.button {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 40px;
  text-decoration: none !important;
  -webkit-box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  min-width: 160px;
  text-align: center;
}

.button-primary {
  background-color: #5c993e;
}

.button-primary p {
  color: white !important;
}

.button-primary:hover {
  background-color: #558c3a;
}

.button-primary:active {
  background-color: #4d8034;
}

.button-secondary {
  background-color: white;
  border: solid 3px #5c993e;
}

.button-secondary {
  color: #5c993e !important;
}

.button-secondary:hover {
  background-color: #f5f5f5;
}

.button-secondary:active {
  background-color: #efefef;
}

.button:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button p {
  text-align: center;
  text-decoration: none !important;
  font-weight: 600;
}
