body {
  background-color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #2d2d2d;
  font-size: 18px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  overscroll-behavior-x: contain;
  touch-action: pan-y;
}

#scroll {
  width: 100%;
  height: 100vh;
  border: 1px white solid;
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}

.mountain-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.react-datepicker .react-datepicker-container .react-datepicker-top {
    background: linear-gradient(#5c993e, #5c993e) !important;
    border-top: 1px solid #5c993e !important;
    border-left: 1px solid #5c993e !important;
    border-right: 1px solid #5c993e !important;
}
.react-datepicker:not(.position-top) .react-datepicker-container .react-datepicker-top::after {
    border-bottom-color: #5c993e !important;
}

.nav-link {
  font-weight: 600;
  margin-left: 25px;
  cursor: pointer;
  text-decoration: none;
}

.nav-link.active {
  cursor: default;
}

.nav-link:not(.active) {
  color: #2d2d2d;
}
