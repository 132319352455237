.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 5;
  overflow: hidden;
}

.modal-wrapper:not(.active) .overlay {
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
}

.modal-wrapper:not(.active) .modal {
  transform: translate3d(0, 100%, 0);
  -webkit-transform: translate3d(0, 100%, 0);
  pointer-events: none;
  opacity: 0;
}

.modal-wrapper:not(.switch) .modal-content-2 {
  display: none;
}

.modal-wrapper.switch .modal-content-1 {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s ease-out;
  pointer-events: auto;
}

.modal {
  position: relative;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  opacity: 1;
  transition: all 0.4s ease-out;
  z-index: 1;
  pointer-events: auto;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-icon {
  width: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.25;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.modal-actions a {
  color: #5c993e;
  font-weight: 600;
  margin-right: 20px;
}

@media all and (max-width: 1040px) {
  .modal-wrapper {
    align-items: flex-start;
  }
  .modal {
    margin: 20px;
    overflow: scroll;
    padding: 50px 25px;
    width: 100%;
    height: calc(100% - 40px);
  }
  .modal-content {
    width: 100%;
  }
}
