.faq {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 40px 20px;
}

.faq-title {
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 35px;
}
